import React, { useState } from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; // Import the arrow icon
import './Works.css';

// Import project images
import LTS from '../../assets/recentprojects/tuneto.webp';
//import UBB from '../../assets/recentprojects/ubb.gif'; //old files moved to imgur for fast loading
//import ARiot from '../../assets/recentprojects/ariot.gif';
//import MRating from '../../assets/recentprojects/movierate.gif';
//import Glist from 'https://i.imgur.com/YXFxnC2.gif';

const useStyles = makeStyles(() => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
  arrowWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1.5em',
    cursor: 'pointer',
  },
  arrowIcon: {
    fontSize: '2rem',
    transition: 'transform 0.3s',
  },
  arrowExpanded: {
    transform: 'rotate(180deg)',
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const [projects] = useState([
    { 
      id: 1,
      title: 'LTS using TUnet', 
      description: `Cutting-edge deep learning model leveraging TransUNet for Liver Tumor Segmentation in medical imaging, 
                    achieving a 12% increase in accuracy compared to traditional UNet models. This improvement was driven by 
                    incorporating advanced augmentation techniques.`,
      alter: 'LTS Using TransUnet',
      image: LTS,
    },
    { 
      id: 2,
      title: 'Unified Blood Bank', 
      description: `Django-based web application for a unified blood bank system where donors can easily register 
                    and users can view donor data based on location. The application is successfully deployed on Microsoft 
                    Azure for robust performance and accessibility.`,
      alter: 'Unified Blood Bank',
      image: "https://imgur.com/TYZ45QX.gif",
    },
    { 
      id: 3,
      title: 'AR Home IoT', 
      description: `Augmented Reality home automation system utilizing Unity3D, Vuforia Engine, and Blynk API. 
                    This proof-of-concept project showcases innovative integration of AR technologies to control home 
                    devices seamlessly.`,
      alter: 'AR Home IoT',
      image: 'https://imgur.com/fL3vapG.gif',
    },
    { 
      id: 4,
      title: 'Movie Rating Finder Bot', 
      description: `Movie Rating Finder Bot using UiPath as part of the UiPath Citizen Developer Skillathon. 
                    This bot efficiently retrieves movie ratings, showcasing automation skills and leveraging the 
                    capabilities of UiPath for real-world applications.`,
      alter: 'Movie Rating Finder Bot',
      image: "https://imgur.com/otjuiPB.gif",
    },
    { 
      id: 5,
      title: 'Grocery List App', 
      description: `Comprehensive Grocery Application for Android as part of my Android Application 
                    Development internship using Kotlin. This app streamlines the shopping experience, offering 
                    a user-friendly interface and robust functionality.`,
      alter: 'Grocery List App',
      image: 'https://imgur.com/24wWhgp.gif',
    },
    {
      title: 'More Projects',
      description: 'Working on it.'
    },
  ]);

  const displayedProjects = showAll ? projects : projects.slice(0, 3);

  return (
    <section id="works">
      <Typography variant="h3" align="center" style={{ paddingLeft: '4rem' }} gutterBottom >
        PROJECTS
      </Typography>
      <Container component="main" className={classes.main} maxWidth="md">
        {displayedProjects.map((project) => (
          <div className="project" key={project.id}>
            <div className="__img_wrapper">
              <div className="laptop-border">
                <img className="project-image" src={project.image} alt={project.alter} />
              </div>
            </div>
            <div className="__content_wrapper">
              <h3 className="title">{project.title}</h3>
              <p className={`description ${showAll ? 'expanded' : ''}`}>
                {project.description}
              </p>
            </div>
          </div>
        ))}
        <div 
          className={classes.arrowWrapper} 
          onClick={() => setShowAll(!showAll)}
        >
          <ExpandMoreIcon 
            className={`${classes.arrowIcon} ${showAll ? classes.arrowExpanded : ''}`} 
          />
          <span className={classes.arrowText}>
            {showAll ? 'Show Less Projects' : 'Show More Projects'}
          </span>
        </div>
      </Container>
    </section>
  );
};
