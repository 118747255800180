import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles"; // For theme detection

// Import the PNG images for light and dark themes
import darkLogo from "../../assets/images/logo-dark.webp"; // Path to the dark theme logo
import lightLogo from "../../assets/images/logo-light.webp"; // Path to the light theme logo

const useStyles = makeStyles((theme) => ({
  logoImage: {
    width: "100%", // Allow the logo to fill the container width
    height: "auto", // Maintain aspect ratio
    objectFit: "contain", // Ensures the logo fits within the container
  },
  logoContainer: {
    maxWidth: "200px", // Maximum width for larger screens
    aspectRatio: "4 / 1", // Maintain the aspect ratio of the logo (adjust as needed)
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden", // Ensure the logo stays within the container

    // Media query for small screens
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px", // Reduce max width on smaller screens
    },
  },
}));

export const Logo = () => {
  const classes = useStyles();
  const theme = useTheme(); // Get the current theme

  // Choose the logo based on the current theme (dark or light)
  const logoSrc = theme.palette.type === "dark" ? darkLogo : lightLogo;

  return (
    <div className={classes.logoContainer}>
      <img
        src={logoSrc}
        alt="Logo"
        className={classes.logoImage}
      />
    </div>
  );
};
