import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { TextDecrypt } from '../content/TextDecrypt';
import { ResumeIcon } from '../content/ResumeButton';
import Swal from 'sweetalert2';
import ResumePDF from './../../assets/Jobin_resume.pdf';

const useStyles = makeStyles((theme) => ({
  footerText: {
    position: 'fixed',
    bottom: theme.spacing(7.2),
    left: theme.spacing(6),
    '&:hover': {
      color: theme.palette.primary.main,
    },
    transition: 'all 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export const Resume = () => {
  const classes = useStyles();

  const handleResumeClick = (e) => {
    e.preventDefault();

    // Show the SweetAlert2 prompt
    Swal.fire({
      title: 'Thank you for considering',
      html: `
      <div>
        <p>
          Please send an email with the required details you will get the latest resume back... <br />
          or download old Resume <a href="${ResumePDF}" download="Resume">👉🏻HERE👈🏻</a>.
        </p>
      </div>
    `,
      imageUrl: "https://img.icons8.com/color/96/resume.png", // Use the provided resume icon
      imageWidth: 64, // Adjust width for proper size
      imageHeight: 64, // Adjust height for proper size
      showCancelButton: true,
      confirmButtonText: 'Send Email',
      cancelButtonText: 'Cancel',
      didOpen: () => {
        const popup = Swal.getPopup();
        if (popup) {
          popup.style.background = 'rgba(255, 255, 255, 0.7)';
          popup.style.boxShadow = 'none';
          popup.style.backdropFilter = 'blur(10px)';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect to mailto
        const mailtoLink = `mailto:hello@jobinabraham.me?subject=Request for Resume&body=Hi,%0A%0APlease share your resume. Please find the job details below.%0A%0AI look forward to hearing back from you.%0A%0AThank you!%0A%0ABest regards,%0AYour Name`;
        window.location.href = mailtoLink;
      }
    });
  };

  return (
    <Link
      color="inherit"
      underline="none"
      href="#"
      onClick={handleResumeClick}
      className={classes.footerText}
    >
      <ResumeIcon />
      {/* <Typography component="span">
        <TextDecrypt text={' \nResume'} />
      </Typography> */}
    </Link>
  );
};
