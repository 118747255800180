import React from "react";
import { Link, Tooltip, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Resume from "../../settings/resume.json";
import { Logo } from "./Logo";
import { Today } from "../content/Today";

const useStyles = makeStyles((theme) => ({
  svg: {
    position: "fixed",
    width: "200px", // Adjust width to match your logo aspect ratio
    height: "50px", // Height based on your logo's aspect ratio (4:1 ratio)
    flexDirection: "row",
    top: theme.spacing(6),
    left: theme.spacing(6),
    boxShadow:
      "0px 4px 10px rgba(0, 0, 0, 0.15), 0px 6px 15px rgba(0, 0, 0, 0.1)", // Smoother and more blended box shadow
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer", // Ensure the logo is clickable
    borderRadius: "8px", // Optionally add some border radius for smoother edges
    transition: "box-shadow 0.3s ease-in-out", // Smooth transition for hover effect
  },
  greeting: {
    marginLeft: theme.spacing(1), // Space between logo and greeting
    fontSize: "1rem",
    color: theme.palette.text.primary,
    fontStyle: "italic",
  },
}));

export const LogoLink = () => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<Today/>}
      placement="right"
      TransitionComponent={Zoom}
    >
      <Link
        variant="h6"
        href={Resume.basics.url}
        underline="none"
        color="inherit"
        noWrap
        className={classes.svg}
      >
        <Logo />
      </Link>
    </Tooltip>
  );
};
