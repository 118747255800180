import React, { useEffect, useState } from "react"; // Import necessary React hooks
import { Typography, Container } from "@material-ui/core"; // Import Material-UI components
import { makeStyles } from "@material-ui/core/styles"; // Material-UI makeStyles for styling
import { TextDecrypt } from "./TextDecrypt"; // Assuming TextDecrypt is a component that decrypts text
import Resume from "../../settings/resume.json"; // Import resume data (JSON)
import { FirstName, LastName } from "../../utils/getName"; // Import names dynamically
import { useTheme } from '@material-ui/core/styles'; // To get the current theme (light/dark)
import dev_Dark from '../../assets/images/Dev-d2.webp'; // Dark theme profile image
import dev_Light from '../../assets/images/Dev-l2.webp'; // Light theme profile image

// Custom styling using makeStyles
const useStyles = makeStyles((theme) => ({
  // Main wrapper for the content
  main: {
    maxWidth: '100vw', // Ensure full width

    // Common styles
    marginTop: "auto",
    marginBottom: "auto",
    display: 'flex',
    justifyContent: 'center', // Centers horizontally
    alignItems: 'center',
    flexWrap: 'wrap', // Allow wrapping for child elements
    padding: theme.spacing(4), // Padding around the content
    paddingLeft: '100px',
    paddingTop:'90px',

    // Styles for mobile screens (default)
    flexDirection: 'column', // Stack elements vertically

    // Styles for larger screens (min-width: 768px)
    "@media (min-width: 768px)": {
      flexDirection: 'row', // Stack elements side by side
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft:'300px',
    },
  },

  // Heading styles
  heading: {
    // Common styles
    marginBottom: theme.spacing(2), // Space below the heading
    flex: 1,

    // Styles for mobile screens (default)
    textAlign: 'center', // Center-align text
    left: '20rem',

    // Styles for larger screens (min-width: 768px)
    "@media (min-width: 768px)": {
      textAlign: 'center', // Align text to the left
      marginRight: theme.spacing(5), // Add space on the right
    },
  },

  // Jobs text style
  jobs: {
    // Common styles
    fontStyle: 'italic',
    fontWeight: 'normal',

    // Styles for mobile screens (default)
    fontSize: '1.4rem', // Smaller font size for mobile

    // Styles for larger screens (min-width: 768px)
    "@media (min-width: 768px)": {
      fontSize: '1.5rem', // Larger font size for desktops
    },
  },

  // Image styling
  image: {
    // Common styles
    height: 'auto',
    objectFit: 'cover', // Maintain aspect ratio
    marginBottom: theme.spacing(2), // Space below the image
    transition: 'mask-image 0.3s ease-in-out, filter 0.3s ease-in-out',
    maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)',
    WebkitMaskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)',
    maskComposite: 'intersect',
    WebkitMaskComposite: 'destination-in',
    filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))',

    // Styles for mobile screens (default)
    width: '60vw', // Smaller width for mobile
    maxWidth: '350px', // Limit the maximum width

    // Styles for larger screens (min-width: 768px)
    "@media (min-width: 768px)": {
      width: '30vw', // Wider for desktop
      maxWidth: '400px', // Increased max width
      marginLeft: theme.spacing(5), // Add space on the left
      marginBottom: 0, // Remove bottom margin
    },
  },

  // Name text styles
  name: {
    // Common styles
    textShadow: '0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.7), 0 0 30px rgba(0, 255, 255, 0.7)', // Glow effect

    // Styles for mobile screens (default)
    textAlign: 'center', // Center-align text
    fontSize: '3.1rem', // Font size for mobile

    // Styles for larger screens (min-width: 768px)
    "@media (min-width: 768px)": {
      fontSize: '4.5rem', // Larger font size
      //textAlign: 'left', // Align text to the left
    },
  },

  // Typewriter text styles
  typedText: {
    // Common styles
    fontFamily: "'Courier New', monospace", // Use typewriter-like font
    letterSpacing: '1px', // Add spacing between letters
    paddingTop:'10px',

    // Styles for mobile screens (default)
    fontSize: '1.2rem', // Default font size for mobile

    // Styles for larger screens (min-width: 768px)
    "@media (min-width: 768px)": {
      fontSize: '1.5rem', // Increase font size for desktops
      paddingTop:'10px',
    },
  },
}));



// List of texts to type out dynamically
const texts = ['Software Engineering', 'Web Development', 'Cloud Enthusiast', 'Blogging', 'UI Designing'];

export const Content = () => {
  const classes = useStyles(); // Apply styles
  const theme = useTheme(); // Get the current theme
  const [index, setIndex] = useState(0); // Track the current text to display
  const [typedText, setTypedText] = useState(""); // Store the typed text
  const [isTyping, setIsTyping] = useState(true); // Track if typing is in progress

  // Select image based on current theme (light or dark)
  const imageSrc = theme.palette.type === 'dark' ? dev_Dark : dev_Light;

  // Typing effect logic
  useEffect(() => {
    let currentWord = texts[index]; // Get current word to type
    let currentIndex = 0; // Start from the first character

    const typingInterval = setInterval(() => {
      if (currentIndex < currentWord.length) {
        setTypedText((prev) => prev + currentWord[currentIndex]); // Add one character at a time
        currentIndex++;
      } else {
        setIsTyping(false); // Stop typing after word is completed
        clearInterval(typingInterval);
      }
    }, 100); // Typing speed (100ms per character)

    // Cleanup on component unmount or index change
    return () => clearInterval(typingInterval);
  }, [index]); // Re-run when the index changes

  // Logic to change word after typing is completed
  useEffect(() => {
    if (!isTyping) {
      const changeWordTimeout = setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length); // Cycle through the texts
        setTypedText(""); // Reset typed text for next word
        setIsTyping(true); // Start typing the next word
      }, 1500); // Wait for 1.5 seconds before changing word

      // Cleanup on component unmount or typing change
      return () => clearTimeout(changeWordTimeout);
    }
  }, [isTyping, index]);

  return (
    <Container component="main" className={classes.main} maxWidth="md">
      {/* Display profile image */}
      <img src={imageSrc} alt="Profile" className={classes.image} />
      <div className={classes.heading}>
        {/* Display Name */}
        <Typography variant="h3" component="h3" className={classes.name}>
          <TextDecrypt text={`${FirstName} ${LastName}`} />
        </Typography>
        {/* Display job titles */}
        <Typography variant="h3" component="h5" className={classes.jobs}>
          <TextDecrypt text={`${Resume.basics.job1}`} />
          <TextDecrypt text={`${Resume.basics.job2}`} />
        </Typography>
        {/* Display typed text with typing effect */}
        <Typography variant="h4" component="h6" className={classes.typedText}>
          {typedText}
        </Typography>
      </div>
    </Container>
  );
};
