import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import './Certificates.css';

export const Certificates = () => {
  const totalCertificates = 11; // Update this to the actual number of certificates

  // Generate certificate data dynamically
  const certificates = Array.from({ length: totalCertificates }, (_, index) => {
    const id = index + 1;
    return {
      id,
      title: `Certificate ${id}`,
      description: `Description of Certificate ${id}.`,
      imageUrl: `/certs/c${id}.webp`,
      thumbnailUrl: `/certs/c${id}.webp`,
    };
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const scrollRef = useRef(null);

  // Handle navigation in the slideshow
  const nextImage = () => {
    if (currentImageIndex < certificates.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0); // Loop back to the first image
    }
  };

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else {
      setCurrentImageIndex(certificates.length - 1); // Loop back to the last image
    }
  };

  // Auto-scroll through the certificates
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < certificates.length - 1 ? prevIndex + 1 : 0
      );
    }, 3000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentImageIndex, certificates.length]);

  return (
    <section id="certificates" className="certificates-section">
      <Typography variant="h3" align="center" gutterBottom>
        CERTIFICATES
      </Typography>

      <div className="certificates-wrapper">
        <div className="certificates-list" ref={scrollRef}>
          {/* Display thumbnails of certificates in a horizontally scrolling row */}
          <div className="certificates-thumbnails">
            {certificates.map((certificate, index) => (
              <div
                key={certificate.id}
                className="thumbnail-item"
                onClick={() => setCurrentImageIndex(index)}
              >
                <img
                  src={certificate.thumbnailUrl}
                  alt={`Thumbnail of ${certificate.title}`}
                  className="thumbnail-image"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Preview of the current certificate within the same container */}
        <div className="preview-container">
          <IconButton onClick={prevImage} className="preview-nav-btn">
            <ArrowBack />
          </IconButton>

          <img
            src={certificates[currentImageIndex].imageUrl}
            alt={`Certificate ${currentImageIndex + 1}`}
            className="preview-image"
          />

          <IconButton onClick={nextImage} className="preview-nav-btn">
            <ArrowForward />
          </IconButton>
        </div>
      </div>
    </section>
  );
};
