/* eslint-disable no-unused-vars */
import React from "react";
import { useRef, useState } from "react";
import { Container, Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Contact.css';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "3em",
  },
  form: {
    width: '100%',
  },
  formfield: {
    width: '100%',
    marginBottom: '2rem',
  },
}));

export const Contact = () => {
  const classes = useStyles();
  const greetings = "Say hello. 👋🏼";

  // State to manage form values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Update form state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Send email via mailto
  const sendEmail = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    // Construct mailto URL
    const mailtoLink = `mailto:example@example.com?subject=Message from ${encodeURIComponent(
      name
    )}&body=Name: ${encodeURIComponent(name)}%0AEmail: ${encodeURIComponent(
      email
    )}%0A%0A${encodeURIComponent(message)}`;

    // Redirect to mailto URL
    window.location.href = mailtoLink;
  };

  return (
    <section id="contact">
      <Container component="main" className={classes.main} maxWidth="md">
        <div className="contact">
          <div className="_form_wrapper">
            <form onSubmit={sendEmail} className={classes.form}>
              <TextField
                id="outlined-name-input"
                label="Name"
                type="text"
                size="small"
                variant="filled"
                name="name"
                className={classes.formfield}
                value={formData.name}
                onChange={handleInputChange}
              />
              <TextField
                id="outlined-email-input"
                label="Email"
                type="email"
                size="small"
                variant="filled"
                name="email"
                className={classes.formfield}
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextField
                id="outlined-message-input"
                label="Hey Jobin!"
                type="textarea"
                size="small"
                multiline
                minRows={5}
                variant="filled"
                name="message"
                className={classes.formfield}
                value={formData.message}
                onChange={handleInputChange}
              />
              <button type="submit" className="submit-btn">
                <i className="fas fa-terminal"></i>
                <Typography component="span"> SEND</Typography>
              </button>
            </form>
          </div>
          <h1 className="contact_msg">
            <TextDecrypt text={greetings} />
          </h1>
        </div>
      </Container>
    </section>
  );
};
