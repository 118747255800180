import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.secondary.main,
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&:focus': {
      transform: 'scale(1.1)',
    },
    transition: 'transform 0.15s cubic-bezier(0.2, 0, 0.13, 2)',
    transform: 'scale(1)',
    overflow: 'visible !important',
  },
}));

export const ResumeIcon = () => {
  const classes = useStyles();

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 96"
      width="24"
      height="24"
      className={classes.svgHover}
    >
      <path 
        d="M44.775 0H22.8C17.973 0 14.4 3.564 14.4 8.362v79.275c0 4.8 3.573 8.362 8.4 8.362h50.4c4.8 0 8.4-3.562 8.4-8.362V25.087L44.775 0zM38.625 32.55h18.75c1.12 0 2.025.908 2.025 2.025v6.75c0 1.12-.905 2.025-2.025 2.025H38.625a2.03 2.03 0 01-2.025-2.025v-6.75a2.03 2.03 0 012.025-2.025zm30.375 37.8H26.25v-4.05h42.75v4.05zm0-14.85H26.25v-4.05h42.75v4.05zm-21.6-32.55v14.85h18.225L47.4 23.625z"
        fill="currentColor"
      />
    </svg>
  );
};

export const HeartIcon = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      className={classes.svgHover}
    >
      <path fillRule="evenodd" d="M4.25 2.5c-1.336 0-2.75 1.164-2.75 3 0 2.15 1.58 4.144 3.365 5.682A20.565 20.565 0 008 13.393a20.561 20.561 0 003.135-2.211C12.92 9.644 14.5 7.65 14.5 5.5c0-1.836-1.414-3-2.75-3-1.373 0-2.609.986-3.029 2.456a.75.75 0 01-1.442 0C6.859 3.486 5.623 2.5 4.25 2.5zM8 14.25l-.345.666-.002-.001-.006-.003-.018-.01a7.643 7.643 0 01-.31-.17 22.075 22.075 0 01-3.434-2.414C2.045 10.731 0 8.35 0 5.5 0 2.836 2.086 1 4.25 1 5.797 1 7.153 1.802 8 3.02 8.847 1.802 10.203 1 11.75 1 13.914 1 16 2.836 16 5.5c0 2.85-2.045 5.231-3.885 6.818a22.08 22.08 0 01-3.744 2.584l-.018.01-.006.003h-.002L8 14.25zm0 0l.345.666a.752.752 0 01-.69 0L8 14.25z" />
    </svg>
  );
};
